import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Title from "../components/Layout/Title"
import ProjectItem from "../components/Home/Clients/ProjectItem"

import { HeroBackground } from "../components/Layout/HeroBackground"

export const query = graphql`
  query ProjectsQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/work/" } }) {
      edges {
        node {
          frontmatter {
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mission
            description
            stack
            website
            title
            type
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const Work = ({ data }) => {
  const works = data.allMarkdownRemark.edges

  return (
    <Layout flexGrow={true} headerType="standard">
      <SEO title="Work" />
      <HeroBackground />
      <div className="page__header">
        <div className="container">
          <Title upper="Découvrir" lower="Les Projets" color="dark" />
          {/* <p>
            Vous trouverez sur cettes page quelques projets clients ainsi que
            certaines réalisations personelles.
          </p> */}
        </div>
      </div>
      <div className="container">
        <div className="projects--grid">
          {works.map((project: any, index: number) => (
            <ProjectItem
              link={`/work${project.node.fields.slug}`}
              title={project.node.frontmatter.title}
              mission={project.node.frontmatter.mission}
              description={project.node.frontmatter.description}
              img={project.node.frontmatter.thumbnail.childImageSharp.fluid}
              type={project.node.frontmatter.type}
              custom={index}
              key={index}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}
export default Work
