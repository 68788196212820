import React from "react"

// Hero Moving Blobs
import HeroLeft from "../Motion/Hero/HeroLeft"
import HeroMiddle from "../Motion/Hero/HeroMiddle"
import HeroRight from "../Motion/Hero/HeroRight"

interface Props {}

export const HeroBackground = (props: Props) => {
  return (
    <div style={{ position: "fixed", top: 0 }}>
      <HeroLeft />
      <HeroMiddle />
      <HeroRight />
    </div>
  )
}
